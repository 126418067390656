/* eslint-disable */
import {
  MenuItemsType,
  SwapIcon,
  SwapFillIcon,
  EarnIcon,
  TrophyFillIcon,
  MoreIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { DropdownMenuItems } from '@pancakeswap/uikit/src/components/DropdownMenu/types'
import { DropdownMenuItemType } from '@pancakeswap/uikit/src/components/DropdownMenu/types'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean } & {
  items?: ConfigMenuDropDownItemsType[]
}

const filterItemBySupportChainId = (item, chainId) => {
  return !chainId || !item.supportChainIds ? true : item.supportChainIds?.includes(chainId)
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
    [
      {
        label: t('Swap'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/swap',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: t('Pools'),
        icon: SwapIcon,
        fillIcon: SwapFillIcon,
        href: '/liquidity',
        showItemsOnMobile: false,
        items: [],
      },
      {
        label: 'Earn',
        href: 'https://tornadoswap.co/earn',
        icon: EarnIcon,
        type: DropdownMenuItemType.EXTERNAL_LINK,
        showItemsOnMobile: false,
        items: []
      },
      {
        label: "",
        href: "#",
        icon: MoreIcon,
        items :[
          {
            label: "Launchpad",
            href: "https://tornadoswap.co/Launchpad",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Lottery",
            href: "https://tornadoswap.co/lottery",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Airdrop",
            href: "https://tornadoswap.co/airdrop",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Marketplace",
            href: "#sec",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Tornado Swap",
            href: "https://tornadoswap.co/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Document",
            href: "https://docs.tornadoswap.co/",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          },
          {
            label: "Chart",
            href: "https://www.dextools.io/app/en/bnb/pair-explorer/0x021fe65638f80ce7f0484e4c16aff1918144ec40?t=1726992365851",
            type: DropdownMenuItemType.EXTERNAL_LINK,
          }
        ]
      }
    ].filter((item) => filterItemBySupportChainId(item, chainId))

export default config
